import React from "react"
import { graphql } from "gatsby"
import { Layout, LazyIframe, ContactForm } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Contact = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <GatsbySeo
        title={frontmatter.seo_title}
        titleTemplate="CG Homeopathie - %s"
        description={frontmatter.seo_description}
      />
      <h1>{frontmatter.title}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="mt-8 max-w-full">
        <LazyIframe
          title="Locatie CG Homeopathie"
          
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.966444940356!2d5.172537912570687!3d52.02570897259025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c666fd38d87127%3A0xb44dedba2617580b!2sPapiermolen%2030%2C%203994%20DK%20Houten!5e0!3m2!1snl!2snl!4v1733741100856!5m2!1snl!2snl"
        />
      </div>
      <div className="mt-2 px-4">
        <h2>Contactformulier</h2>
        <ContactForm />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "/contactpagina" } }) {
      html
      frontmatter {
        title
        seo_title
        seo_description
      }
    }
  }
`

export default Contact
